import './App.css';
import Minter from './Minter'
import { useState } from 'react';

function App() {

  
  return (
    <div className="App">
      <Minter></Minter>
    </div>
  );
}

export default App;
