
require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const PUBLIC_KEY = process.env.PUBLIC_KEY;
const PRIVATE_KEY = process.env.PRIVATE_KEY;

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const contractAddress = "0xB6E2a86Dc7cBF56B6fe22e93875CBd253a5b478B";

  export const mintNFT = async(address) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address })
    };
    const response = await fetch('https://nftminter.klassedenny.space', requestOptions);
    const data = await response.json();
    //const receipt = await web3.eth.getTransactionReceipt(data.transactionHash)
    const tokenId = web3.utils.hexToNumber(data.logs[0].topics[3])
    //console.log(receipt)
    console.log(data)
    if(data.status = true){
    return {
      status: (
        <span>
            <p>
              View your transaction on <a target="_blank" href={`https://polygonscan.com/tx/`+data.transactionHash}>Polygonscan</a> or the NFT on <a target="_blank" href={`https://opensea.io/assets/matic/`+contractAddress+'/'+tokenId}>OpenSea</a> (it might take a minute to show up).
            </p>
          </span>
      )
    }
    } else {
      return "Error 🤯 Refresh and try again."
    }
    //load smart contract
    /*window.contract = await new web3.eth.Contract(contractABI, contractAddress);//loadContract();

    //set up your Ethereum transaction
    const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        'data': window.contract.methods.mintNFT(window.ethereum.selectedAddress, tokenURI).encodeABI() //make call to NFT smart contract 
    };

    //sign transaction via Metamask
    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });
        return {
            success: true,
            status: "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" + txHash
        }
    } catch (error) {
        return {
            success: false,
            status: "😥 Something went wrong: " + error.message
        }
    }*/
}