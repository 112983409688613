import { useEffect, useState } from "react";
import { mintNFT } from "./utils/interact.js";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from 'ethers';

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");

  const onMintPressed = async () => {
    setStatus("Minting...");
    const status = await mintNFT(account);
    setStatus(status.status);
    setMint(true)
    //mintNFT();
};
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "ea59f8d2fdf44487bbd464821c5cb7fb"
    }
  }
};

const web3Modal = new Web3Modal({
  providerOptions // required
});
const [provider, setProvider] = useState();
const [library, setLibrary] = useState();
const [account, setAccount] = useState();
const [network, setNetwork] = useState();
const [minted, setMint] = useState();

const connectWallet = async () => {
  try {
    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();
    setProvider(provider);
    setLibrary(library);
    if (accounts) setAccount(accounts[0]);
    setNetwork(network);
  } catch (error) {
    console.error(error);
  }
};
  


  return (
    <div className="Minter">
      <button onClick={connectWallet}>
        {account &&
        "Connected to "+account
        }
        {!account &&
        "Connect Wallet"
        }
        </button>
        
      <img className="nftimg" src="https://openseauserdata.com/files/f1ce465877b87e50729d4c8cb9a5aaef.svg"></img>
      {!minted &&
      <button id="mintButton" className={`${account ? "active" : "faded"}`}
       onClick={onMintPressed}>
        Mint NFT
      </button>
      }
      {minted &&
      <button id="mintButton" className="non-clickable">
       Success! 🤩
     </button>
      }
      <p id="status">
        {status}
      </p>
    </div>
  );
};

export default Minter;
